<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="取引履歴">
        <div class="history-wrapper">
          <organization
            :transactions="showTradeHistoryPage"
            :search="search"
            :loading="tradeHistoryLoading"
            @change-search="(x) => $emit('change-search', x)"
          />
          <div class="pagination-wrapper">
            <el-pagination
              layout="prev, pager, next"
              :page-size="tradeHistoryPageSize"
              :current-page.sync="tradeHistoryPageIdx"
              :total="tradeHistorySize"
            />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Organization from './AdminHistories';

export default {
  name: 'organization-page',
  components: { Organization },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    userTransactions: {
      type: Array,
      required: true,
    },
    tradeHistorySize: {
      type: Number,
      required: true,
    },
    userTransactionSize: {
      type: Number,
      required: true,
    },
    tradeHistoryPageSize: {
      type: Number,
      required: true,
    },
    userTransactionPageSize: {
      type: Number,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    tradeHistoryLoading: {
      type: Boolean,
      required: true,
    },
    userHistoryLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tradeHistoryPageIdx: 1,
    };
  },
  computed: {
    showTradeHistoryPage() {
      return this.transactions[this.tradeHistoryPageIdx - 1] || [];
    },
  },
  methods: {
    sendClick() {
      this.$emit('sendClick');
    },
    getClick() {
      this.$emit('getClick');
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination-wrapper {
  padding-top: 16px;
  display: flex;
  justify-content: center;
}

.search-box {
  text-align: right;
}
</style>
