<template>
  <transaction-table
    :transactions="transactions"
    :search="search"
    :loading="loading"
    @change-search="(x) => $emit('change-search', x)"
  />
</template>

<script>
import TransactionTable from './Table';

export default {
  components: {
    TransactionTable,
  },
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
