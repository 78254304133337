<template>
  <div>
    <el-form :inline="true" class="form">
      <el-form-item>
        <el-input
          :value="search"
          @input="(x) => $emit('change-search', x)"
          prefix-icon="el-icon-search"
        />
      </el-form-item>
    </el-form>
    <el-table :data="transactions" v-loading="loading" stripe>
      <el-table-column label="取引日" prop="date">
        <template slot-scope="scope">{{ dateFormat(scope.row.date) }}</template>
      </el-table-column>
      <el-table-column label="取引タイプ">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.t === '0' ? 'primary' : 'success'"
            disable-transitions
            >{{ scope.row.t === '0' ? '受信' : '送信' }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column label="取引相手" prop="name" />
      <el-table-column label="取引時のポイント" prop="amount" />
      <el-table-column label="コメント" prop="message" />
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    transactions: {
      type: Array,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    dateFormat(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      const realHou = ('00' + hours).slice(-2);
      const realMin = ('00' + minutes).slice(-2);
      const realSec = ('00' + seconds).slice(-2);

      const dateStr = [year, month, day].join('/');
      const timeStr = [realHou, realMin, realSec].join(':');

      return `${dateStr} ${timeStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  text-align: right;
}
</style>
