<template>
  <organization-page
    :isAdmin="isAdmin"
    :transactions="transactionPages"
    :tradeHistorySize="transactions.length"
    :tradeHistoryPageSize="limit"
    :search="search"
    :tradeHistoryLoading="tradeHistoryLoading"
    @sendClick="() => (transferModalVisible = true)"
    @getClick="() => (collectionModalVisible = true)"
    @change-search="changeSearch"
  />
</template>

<script>
import OrganizationPage from '@/components/OrganizationPage.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { ActionTypes as RootActions } from '@/store/actions';
import { ActionTypes as CollectionActions } from '@/store/modules/collection/actions';
import { ActionTypes as OrganizationActions } from '@/store/modules/organization/actions';

const stringFilter = (filter) => ({ name }) => {
  return name.match(filter);
};

const dateFilter = (dateRange) => ({ date }) => {
  if (dateRange.length < 2) {
    return true;
  }

  const [start, end] = dateRange;
  const time = new Date(date).getTime();
  //end側に+23時間59分59秒
  return start.getTime() <= time && time <= end.getTime() + 86399000;
};

export default {
  components: {
    OrganizationPage,
  },
  data() {
    return {
      transactionPageIdx: 1,
      search: '',
    };
  },
  computed: {
    ...mapState(['dateRange', 'isAdmin']),
    ...mapState('organization', [
      'userSize',
      'tradeHistory',
      'userHistory',
      'offset',
      'limit',
      'tradeHistoryLoading',
      'userHistoryLoading',
    ]),
    transactions() {
      return this.filterTransactions(this.tradeHistory);
    },
    transactionPages() {
      return this.makeTransactionPage(this.tradeHistory, this.limit);
    },
  },
  methods: {
    ...mapActions('organization', [
      OrganizationActions.getTradeHistory,
      OrganizationActions.getUsers,
      OrganizationActions.getUserHistory,
      OrganizationActions.getUserSize,
      OrganizationActions.setOffset,
    ]),
    changeSearch(x) {
      this.search = x;
    },
    filterTransactions(transactions) {
      if (this.dateRange.length === 0 && this.search.length == 0) {
        return transactions;
      }
      return transactions
        .filter(stringFilter(this.search))
        .filter(dateFilter(this.dateRange));
    },
    makeTransactionPage(transactions, pageSize) {
      let table = [];

      for (
        let i = 0;
        i < this.filterTransactions(transactions).length;
        i += pageSize
      ) {
        table.push(
          this.filterTransactions(transactions).slice(i, i + pageSize)
        );
      }

      return table;
    },
  },
  async created() {
    await this.getUserSize();
    await this.getUsers();
    await this.getUserHistory();
    this.getTradeHistory();
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: space-around;

  button {
    min-width: 128px;
  }
}
</style>
